import { useEffect } from 'react';
import R from 'ramda';
import { pageViewTracking } from '../../ShadeFinder/tracking';
import { eventsNames } from '../helpers/eventsConstants';
import { getBestMatch } from '../helpers';
import { pagesNames } from '../../ShadeFinder/helpers/constants';
import { vtoTypes } from '../config';

export const useEvents = ({
  setVtoActualProductSlug,
  setVtoActualProductSku,
  setIsVtoOpened,
  eventHandler,
  setBestMatch,
  vtoProducts,
  setIsModalOpen,
  onVtoModalClose,
  setShowStartHero,
  vtoScriptLoaded,
  vtoType,
  productRecommendation,
  setShowBuyNowButton,
  setShowDownloadPictureButton,
  setIsDownloadPictureOpen,
}) =>
  useEffect(() => {
    let cameraOpened;
    let cameraClosed;
    let cameraFailed;
    let loading;
    let compare;
    let compareDisabled;
    let reset;
    let shadeFinderComplete;
    let closed;
    let loaded;
    let iconKeyMenuStatusChanged;
    let captureCountdownStatusChanged;
    let fourWayEnabled;
    let fourWayDisabled;
    let shareImage;

    if (window && window.YMK) {
      closed = window.YMK.addEventListener('closed', () => {
        if (onVtoModalClose) {
          onVtoModalClose();
        }

        setIsModalOpen(false);
        setShowStartHero(true);
        setIsVtoOpened(false);
        setVtoActualProductSlug({ actualProductSlug: '' });
        setVtoActualProductSku({ actualProductSku: '' });
        setShowBuyNowButton({ showBuyNowButton: false });
        setShowDownloadPictureButton({ showDownloadPictureButton: false });

        eventHandler({ eventName: eventsNames.VTO_EVENT_CLOSED });
      });

      loaded = window.YMK.addEventListener('loaded', () => {
        if (vtoType !== vtoTypes.SHADE_FINDER) {
          setIsVtoOpened(true);
          setShowBuyNowButton({ showBuyNowButton: true });
          setShowDownloadPictureButton({ showDownloadPictureButton: true });
        }
      });

      iconKeyMenuStatusChanged = window.YMK.addEventListener(
        'iconKeyMenuStatusChanged',
        (status) => {
          setShowBuyNowButton({ showBuyNowButton: status === 'hide' });
          setShowDownloadPictureButton({
            showDownloadPictureButton: status === 'hide',
          });

          if (status === 'show') {
            pageViewTracking({ pageName: 'tooltips', vtoType });
          }
        }
      );

      captureCountdownStatusChanged = window.YMK.addEventListener(
        'captureCountdownStatusChanged',
        (status) => {
          if (status === 'start') {
            setShowBuyNowButton({ showBuyNowButton: false });
            setShowDownloadPictureButton({ showDownloadPictureButton: false });
          } else {
            setShowBuyNowButton({ showBuyNowButton: true });
            setShowDownloadPictureButton({ showDownloadPictureButton: true });
          }
        }
      );

      fourWayEnabled = window.YMK.addEventListener('splitFourWay', () => {
        setShowBuyNowButton({ showBuyNowButton: false });
        setShowDownloadPictureButton({ showDownloadPictureButton: false });
      });

      fourWayDisabled = window.YMK.addEventListener(
        'splitFourWayDisabled',
        () => {
          setShowBuyNowButton({ showBuyNowButton: true });
          setShowDownloadPictureButton({ showDownloadPictureButton: true });
        }
      );

      cameraOpened = window.YMK.addEventListener('cameraOpened', () => {
        pageViewTracking({ pageName: pagesNames.CAMERA_ACCESS, vtoType });
        eventHandler({
          eventName: eventsNames.VTO_GENERIC_EVENT_CAMERA_OPEN,
        });
      });

      cameraClosed = window.YMK.addEventListener('cameraClosed', () => {
        pageViewTracking({ pageName: pagesNames.CAMERA_ACCESS, vtoType });
        eventHandler({
          eventName: eventsNames.VTO_GENERIC_EVENT_CAMERA_CLOSED,
        });
      });

      cameraFailed = window.YMK.addEventListener('cameraFailed', () => {
        eventHandler({ eventName: eventsNames.VTO_ERROR_CAMERA_FAILED });
      });
      loading = window.YMK.addEventListener('loading', (percentageProgress) => {
        if (percentageProgress === 0) {
          eventHandler({ eventName: eventsNames.VTO_LOADING });
        }
      });

      compare = window.YMK.addEventListener('compare', () => {
        eventHandler({
          eventName: eventsNames.VTO_PRODUCT_EVENT_SLIDER_COMPARE,
        });
        eventHandler({
          eventName: eventsNames.VTO_PRODUCT_EVENT_SPLIT,
        });
      });

      compareDisabled = window.YMK.addEventListener('compareDisabled', () => {
        eventHandler({
          eventName: eventsNames.VTO_PRODUCT_EVENT_SLIDER_COMPARE_CLOSED,
        });
      });

      reset = window.YMK.addEventListener('reset', () => {
        eventHandler({
          eventName: eventsNames.VTO_PRODUCT_EVENT_RESET,
        });
      });

      shadeFinderComplete = window.YMK.addEventListener(
        'shadeFinderComplete',
        (data) => {
          setIsVtoOpened(true);
          setShowBuyNowButton({ showBuyNowButton: true });
          setShowDownloadPictureButton({ showDownloadPictureButton: true });
          pageViewTracking({ pageName: pagesNames.TRY_ON, vtoType });

          const bestMatchData = R.prop('recommendations', data);
          console.log(bestMatchData);
          const { bestMatchSku, bestMatchProduct, bestMatchSwatches } =
            getBestMatch({ bestMatchData, vtoProducts, productRecommendation });

          if (bestMatchSku && bestMatchProduct && bestMatchSwatches) {
            setBestMatch({
              bestMatch: {
                bestMatchSku,
                bestMatchProduct,
                bestMatchSwatches,
              },
            });

            setVtoActualProductSlug({
              actualProductSlug: R.prop('slug', bestMatchProduct),
            });
          }
        }
      );

      shareImage = window.YMK.addEventListener('shareImage', (data) => {
        if (data.image) {
          setIsDownloadPictureOpen(data.image);
        } else {
          setIsDownloadPictureOpen(data.base64);
        }
      });
    }

    return () => {
      if (window && window.YMK) {
        window.YMK.removeEventListener(cameraOpened);
        window.YMK.removeEventListener(cameraClosed);
        window.YMK.removeEventListener(cameraFailed);
        window.YMK.removeEventListener(loading);
        window.YMK.removeEventListener(compare);
        window.YMK.removeEventListener(compareDisabled);
        window.YMK.removeEventListener(reset);
        window.YMK.removeEventListener(shadeFinderComplete);
        window.YMK.removeEventListener(closed);
        window.YMK.removeEventListener(loaded);
        window.YMK.removeEventListener(iconKeyMenuStatusChanged);
        window.YMK.removeEventListener(captureCountdownStatusChanged);
        window.YMK.removeEventListener(fourWayEnabled);
        window.YMK.removeEventListener(fourWayDisabled);
        window.YMK.removeEventListener(shareImage);
      }
    };
  }, [
    setVtoActualProductSlug,
    setIsVtoOpened,
    eventHandler,
    setBestMatch,
    vtoProducts,
    setIsModalOpen,
    onVtoModalClose,
    setShowStartHero,
    vtoScriptLoaded,
    vtoType,
  ]);
