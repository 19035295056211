import React, { useState } from 'react';
import { connect } from 'react-redux';
import R from 'ramda';
import PropTypes from 'prop-types';
import { HeroFullCombined, HeroFullOverlay } from '@cotyorg/ccx-organism-hero';
import Heading from '@cotyorg/ccx-atoms/Heading';
import { CardsCarousel } from '@cotyorg/ccx-organism-carousel';
import { CardoGroup } from '@cotyorg/ccx-organism-cardo-group';
import uuid from '@cotyorg/ccx-utils/js/helpers/uuid';
import { trackCarouselClickEvent } from '@cotyorg/ccx-utils/js/tracking/adobe-dtm/events/carousel';
import OptionalSection from '@cotyorg/ccx-utils/js/components/OptionalSection';
import { VideoPlayer } from '@cotyorg/ccx-organism-video';
import {
  trackVideoPlayEvent,
  trackVideoMilestoneEvent,
} from '@cotyorg/ccx-utils/js/tracking/adobe-dtm/events/video';
import StandardLayout from '../../templates/StandardLayout';
import SectionHeader from '../../atoms/SectionHeader';
import { homeCardoBreakpointConfig } from './cardo.config';
import { isSmallBreakpoint, isLargeBreakpoint } from '../../utils';
import ShadeFinder from '../../organisms/ShadeFinder/components/ShadeFinder';

const HomeComponent = ({
  sectionHeadings,
  ctas,
  noOfHeroSection1Heroes,
  noOfHeroSection2Heroes,
  noOfHeroSecondarySection,
  currentBreakpoint,
  videos,
  hero,
  shadeFinder,
}) => {
  const [vtoOpen, setVtoOpen] = useState(false);
  const [vtoProductSlug, setVtoProductSlug] = useState();

  return (
    <StandardLayout>
      <div className="homepage">
        {!!R.prop('mainVideo', videos) && (
          <div className="homepage__video-main">
            <VideoPlayer
              muted
              autoPlay
              videosStoreDataKey="mainVideo"
              showControls={false}
              config={{
                youtube: {
                  playerVars: {
                    controls: 1,
                  },
                  embedOptions: {
                    host: 'https://www.youtube-nocookie.com',
                  },
                },
              }}
              onProgress={({ url, duration, progress, videoMilestone }) =>
                trackVideoMilestoneEvent({
                  url,
                  duration,
                  progress,
                  videoMilestone,
                  title: R.path(['mainVideo', 'title'], videos),
                })
              }
              onPlay={({ url, duration, progress }) =>
                trackVideoPlayEvent({
                  url,
                  duration,
                  progress,
                  title: R.path(['mainVideo', 'title'], videos),
                })
              }
            />
          </div>
        )}
        <div className="homepage-main-hero">
          {R.prop('mainHero', hero) && (
            <HeroFullOverlay
              heroStoreDataKey="mainHero"
              imageHasLink
              layout="vertical"
            />
          )}
        </div>

        {noOfHeroSecondarySection > 0 && (
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell">
                {!!R.prop('heroSecondarySection')(sectionHeadings) && (
                  <SectionHeader>
                    {!!R.path(['heroSecondarySection', 'heading'])(
                      sectionHeadings
                    ) && (
                      <Heading extraClass="section__title" level={2}>
                        {R.path(['heroSecondarySection', 'heading'])(
                          sectionHeadings
                        )}
                      </Heading>
                    )}

                    {!!R.path(['heroSecondarySection', 'introduction'])(
                      sectionHeadings
                    ) && (
                      <Heading extraClass="section__sub-title" level={3}>
                        {R.path(['heroSecondarySection', 'introduction'])(
                          sectionHeadings
                        )}
                      </Heading>
                    )}
                  </SectionHeader>
                )}

                <div className="hero-absolute-image">
                  <HeroFullOverlay
                    heroStoreDataKey="heroSecondarySection"
                    isContentFirst={isLargeBreakpoint({ currentBreakpoint })}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell">
              <SectionHeader>
                <Heading extraClass="section__title" level={2}>
                  {R.path(['heroSection1Heading', 'heading'])(sectionHeadings)}
                </Heading>
                <Heading extraClass="section__sub-title" level={3}>
                  {R.path(['heroSection1Heading', 'introduction'])(
                    sectionHeadings
                  )}
                </Heading>
              </SectionHeader>
              {[...Array(noOfHeroSection1Heroes)].map((item, index) => (
                <HeroFullOverlay
                  key={uuid()}
                  heroStoreDataKey={`heroSection1Hero${index + 1}`}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="grid-container">
          <div className="grid-x grid-margin-x pre-offset-vertical">
            <div className="cell">
              <SectionHeader>
                <Heading extraClass="section__title" level={2}>
                  {R.path(['carouselSection1Heading', 'heading'])(
                    sectionHeadings
                  )}
                </Heading>
                <Heading extraClass="section__sub-title" level={3}>
                  {R.path(['carouselSection1Heading', 'introduction'])(
                    sectionHeadings
                  )}
                </Heading>
              </SectionHeader>
              <CardsCarousel
                cards={{
                  cardsStoreDataKey: 'carousel1',
                  isTitleUnderlined: false,
                  hasBackground: false,
                  isSmallTitle: true,
                  fullWidth: true,
                  headingLevel: 3,
                  fixedHeight: true,
                  hideDescription: true,
                  showStarRating: true,
                  imgRatingOnSrc: {
                    fullStar: '/ccx-files/assets/star-on.svg',
                    partialStar1: '/ccx-files/assets/partial-star-1.svg',
                    partialStar2: '/ccx-files/assets/partial-star-2.svg',
                    partialStar3: '/ccx-files/assets/partial-star-3.svg',
                    partialStar4: '/ccx-files/assets/partial-star-4.svg',
                    partialStar5: '/ccx-files/assets/partial-star-5.svg',
                    partialStar6: '/ccx-files/assets/partial-star-6.svg',
                    partialStar7: '/ccx-files/assets/partial-star-7.svg',
                    partialStar8: '/ccx-files/assets/partial-star-8.svg',
                    partialStar9: '/ccx-files/assets/partial-star-9.svg',
                  },
                  imgRatingOffSrc: '/ccx-files/assets/star-off.svg',
                  ratingIconWidth: 20,
                  ratingIconHeight: 20,
                  additionalOnClick: shadeFinder
                    ? ({ slug }) => {
                        setVtoOpen(true);
                        setVtoProductSlug(slug);
                      }
                    : null,
                }}
                carousel={{
                  carouselsStoreDataKey: 'carousel1',
                  headingLevel: 4,
                  rightChevronIconSrc: '/ccx-files/assets/arrow-right.svg',
                  leftChevronIconSrc: '/ccx-files/assets/arrow-left.svg',
                  onIndexChange: ({ type, index, isForward }) =>
                    trackCarouselClickEvent({
                      page: 'home',
                      carousel: 'carousel1',
                      type,
                      index,
                      isForward,
                    }),
                }}
                cardsPerPageMap={{
                  xsmall: 1,
                  small: 2,
                  medium: 3,
                  large: 4,
                  xlarge: 4,
                }}
              />
            </div>
          </div>
        </div>
        <div className="offset-vertical">
          <div className="flex-container align-center">
            <a
              className="homepage__cta"
              href={R.path(['carouselSection1', 'url'], ctas)}
            >
              {R.path(['carouselSection1', 'text'], ctas)}
            </a>
          </div>
        </div>

        <OptionalSection
          renderCondition={
            !R.isEmpty(
              R.path(['heroSection2Heading', 'heading'])(sectionHeadings)
            )
          }
          section={() => (
            <div className="grid-container">
              <div className="grid-x grid-margin-x">
                <div className="cell">
                  <SectionHeader>
                    <Heading extraClass="section__title" level={2}>
                      {R.path(['heroSection2Heading', 'heading'])(
                        sectionHeadings
                      )}
                    </Heading>
                    <Heading extraClass="section__sub-title" level={3}>
                      {R.path(['heroSection2Heading', 'introduction'])(
                        sectionHeadings
                      )}
                    </Heading>
                  </SectionHeader>
                  <div className="hero-absolute-image">
                    {[...Array(noOfHeroSection2Heroes)].map((item, index) => (
                      <HeroFullOverlay
                        key={uuid()}
                        heroStoreDataKey={`heroSection2Hero${index + 1}`}
                        isContentFirst={isLargeBreakpoint({
                          currentBreakpoint,
                        })}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="grid-x grid-padding-x flex-container align-middle align-center">
                <CardoGroup
                  data="cardSection1Cards"
                  contentTheme="alpha"
                  baseBreakpointConfig={homeCardoBreakpointConfig}
                />
              </div>
            </div>
          )}
        />

        {!!shadeFinder && (
          <div className="homepage__section-try-on">
            <ShadeFinder
              shadeFinderConfiguration={shadeFinder}
              isOpen={vtoOpen}
              onClose={() => setVtoOpen(false)}
              vtoActualProductSlug={vtoProductSlug}
            />
          </div>
        )}
      </div>
    </StandardLayout>
  );
};

HomeComponent.propTypes = {
  sectionHeadings: PropTypes.objectOf(PropTypes.object),
  ctas: PropTypes.objectOf(PropTypes.object),
  currentBreakpoint: PropTypes.string.isRequired,
  noOfHeroSection1Heroes: PropTypes.number,
  noOfHeroSection2Heroes: PropTypes.number,
  noOfHeroSecondarySection: PropTypes.number,
  hero: PropTypes.objectOf(PropTypes.object) || PropTypes.null,
  videos: PropTypes.object,
  shadeFinder: PropTypes.object,
};

export default connect(({ content, breakpoints }) => ({
  currentBreakpoint: R.prop('current', breakpoints),
  sectionHeadings: R.prop('sectionHeadings', content),
  ctas: R.prop('ctas', content),
  noOfHeroSection1Heroes: R.path(['custom', 'noOfHeroSection1Heroes'], content),
  noOfHeroSection2Heroes: R.path(['custom', 'noOfHeroSection2Heroes'], content),
  noOfHeroSecondarySection: R.path(
    ['custom', 'noOfHeroSecondarySection'],
    content
  ),
  hero: R.prop('hero', content),
  videos: R.prop('videos', content),
  shadeFinder: R.prop('shadeFinder')(content),
}))(HomeComponent);
