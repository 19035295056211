import React, { useState } from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
// import { getCategories } from '@cotyorg/ccx-organism-filter/components/shared/helpers';
import { generateVtoControlsClassNames } from '../../helpers';
import { AllShades } from '../AllShades/AllShades';
import { ShopNow } from '../../atoms/ShopNow';
import { DownloadPicture } from '../../atoms/DownloadPicture';
import { shopNowTracking, downloadPictureOpenTracking } from '../../../ShadeFinder/tracking';
import { getTrackingProduct } from '../../handlers';
import { vtoTypes } from '../../config';
// import { Filter } from '../../molecules/Filter/Filter';
import { ProductSelect } from '../ProductSelect/ProductSelect';

export const NailFinder = ({
  selectedProduct,
  vto,
  selectedProductSwatch,
  setVtoActualProductSlug,
  vtoActualProductSku,
  onSetProductID,
  setVtoActualProductSku,
  swatches,
  isVtoLoaded,
  setIsCartOpen,
  useShoppingCart,
  // selectedFilters = [],
  // vtoProducts,
  showBuyNowButton,
  showDownloadPictureButton,
  vtoType,
  defaultProductSku,
}) => {
  const [isProductSelectOpen, setIsProductSelectOpen] = useState(false);

  const productSelect = R.prop('productSelect', vto);
  // const categories = getCategories({
  //   categoryNames: R.pathOr([], ['nailFinder', 'categoryNames'], vto),
  //   products: vtoProducts,
  //   selectedFilters,
  // });

  const miniCardoList = R.path(['miniCardoList', vtoTypes.NAIL_VTO], vto);

  const selectedSwatchItemName = R.prop('itemName', selectedProductSwatch);
  const productUrl =
    !!selectedProduct &&
    `${R.prop('productUrl', selectedProduct)}${
      selectedProductSwatch ? `?shade=${selectedSwatchItemName}` : ''
    }`;

  return (
    <div
      className={generateVtoControlsClassNames({
        isVtoLoaded,
        extraClass: 'nail-finder-vto',
      })}
      style={{
        minHeight: `${R.prop('vtoControlsHeight', vto)}px`,
      }}
    >
      <div className="vto__modal-button-wrapper">
        {!!productUrl && showBuyNowButton && (
          <ShopNow
            extraClass="vto__modal-shop-now-button"
            productUrl={useShoppingCart ? '' : productUrl}
            onClick={() => {
              const product = getTrackingProduct({
                selectedProductSwatch,
                swatches,
              });

              if (useShoppingCart) {
                setIsCartOpen(true);
              }

              if (!useShoppingCart && !!R.prop('item_id', product)) {
                shopNowTracking({
                  shadeName: selectedSwatchItemName,
                  product,
                  vtoType,
                });
              }
            }}
          />
        )}

        {R.path(['downloadPicture', 'downloadText'], vto) &&
          showDownloadPictureButton && (
            <DownloadPicture
              extraClass="vto__modal-download_picture-button"
              onClick={() => {
                if (window.YMK) {
                  try {
                    YMK.retake();

                    downloadPictureOpenTracking({
                      shadeName: R.prop('itemName', selectedProductSwatch),
                      vtoType,
                    });
                  } catch (error) {
                    console.error(error);
                  }
                }
              }}
            />
          )}
      </div>

      {showBuyNowButton && (
        <p className="nail-finder-vto__shade-name">{selectedSwatchItemName}</p>
      )}

      <ProductSelect
        displayModalButton={false}
        productSelect={productSelect}
        onSelect={({ slug }) => {
          setVtoActualProductSlug({ actualProductSlug: slug });
          setIsProductSelectOpen(false);
        }}
        onOpen={() => setIsProductSelectOpen(true)}
        onClose={() => setIsProductSelectOpen(false)}
        selectedProduct={selectedProduct}
        miniProductCardoList={miniCardoList}
        wrapperExtraClass="vto-controls-wrapper flex-container align-spaced"
        openIconSrc="/ccx-files/assets/chevron-white-down.svg"
        closeIconSrc="/ccx-files/assets/chevron-white-up.svg"
      >
        {/* <Filter
          filterData={R.prop('filter', vto)}
          filters={categories}
          selectedFilters={selectedFilters}
          // onApplyButtonClick={({ selectedFilters: appliedFilters }) =>
          //   onApplyFiltersButtonClick({
          //     appliedFilters,
          //     setSelectedFilters,
          //     eventHandler,
          //   })
          // }
          // onResetButtonClick={() =>
          //   onResetFiltersButtonClick({
          //     selectedFilters,
          //     setSelectedFilters,
          //     eventHandler,
          //   })
          // }
          // onFiltersButtonClick={({ isOpen }) => {
          //   if (!isOpen) {
          //     eventHandler({
          //       eventName: "vto-generic-event-select-filter-open",
          //     });
          //   }
          // }}
          // onFilterSelect={({ filterName, option, isSelected }) => {
          //   if (!isSelected) {
          //     eventHandler({
          //       eventName: "vto-generic-event-filter-select",
          //       selectedFilter: `${filterName}:${R.prop("name", option)}`,
          //     });
          //   }
          // }}
        /> */}
      </ProductSelect>

      {!isProductSelectOpen && (
        <div className="vto-controls-wrapper">
          <AllShades
            defaultProductSku={defaultProductSku}
            vtoActualProductSku={vtoActualProductSku}
            onSetProductID={onSetProductID}
            setVtoActualProductSku={setVtoActualProductSku}
            swatches={swatches}
            enableRemoveSelectionSwatch
            vtoType={vtoType}
          />
        </div>
      )}
    </div>
  );
};

NailFinder.propTypes = {
  selectedProduct: PropTypes.object,
  vto: PropTypes.object.isRequired,
  selectedProductSwatch: PropTypes.object,
  setVtoActualProductSlug: PropTypes.string,
  vtoActualProductSku: PropTypes.string,
  onSetProductID: PropTypes.func.isRequired,
  setVtoActualProductSku: PropTypes.func.isRequired,
  swatches: PropTypes.array.isRequired,
  isVtoLoaded: PropTypes.bool,
  setIsCartOpen: PropTypes.func.isRequired,
  useShoppingCart: PropTypes.object,
  showBuyNowButton: PropTypes.bool,
  showDownloadPictureButton: PropTypes.bool,
  // selectedFilters: PropTypes.array,
  // vtoProducts: PropTypes.array.isRequired,
  vtoType: PropTypes.string.isRequired,
  defaultProductSku: PropTypes.string,
};
