import R from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@cotyorg/ccx-atoms/Button';
import Modal from 'react-modal';
import IconButton from '@cotyorg/ccx-molecules/IconButton';
import { connect } from 'react-redux';
import PrivacyPolicy from './PrivacyPolicy';
import BestResultsGuide from './BestResultsGuide';
import VtoModal from '../../VtoModal/VtoModal';
import {
  shadeFinderSteps,
  privacyPolicyConsentStates,
} from '../helpers/constants';
import { modalStyles } from '../helpers/modalStyles';
import ShadeFinderQuiz from '../../ShadeFinderQuiz/components/ShadeFinderQuiz';
import { vtoTypes } from '../../VtoModal/config';
import { getVtoType } from '../../VtoModal/helpers';

const ShadeFinder = ({
  vtoActualProductSlug,
  actualProductSku,
  shadeFinderConfiguration,
  isOpen,
  onClose,
  vto,
  hideVtoButton = false,
}) => {
  const actualProductSlug =
    R.prop('actualProductSlug', vto) || vtoActualProductSlug;
  const vtoType = useMemo(
    () =>
      getVtoType({
        actualProductSlug,
        shadeFinderProducts: R.path(['products', vtoTypes.SHADE_FINDER], vto),
        makeupFinderProducts: R.path(['products', vtoTypes.MAKEUP_FINDER], vto),
      }),
    [actualProductSlug, R.prop('products', vto)]
  );

  const vtoProducts = R.path(['products', vtoType], vto);
  const isShadeFinder = vtoType === vtoTypes.SHADE_FINDER;

  const [shadeFinderStep, setShadeFinderStep] = useState(
    shadeFinderSteps.POLICY
  );
  const [modalIsOpen, setIsOpen] = useState(false);
  const [privacyPolicyConsent, setPrivacyPolicyConsent] = useState(
    privacyPolicyConsentStates.DISAGREE
  );
  const [quizAnswers, setQuizAnswers] = useState([]);
  const [productRecommendation, setProductRecommendation] = useState('');

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  const closeModal = () => {
    setIsOpen(false);
    setShadeFinderStep(shadeFinderSteps.POLICY);
    setQuizAnswers([]);

    if (onClose) {
      onClose();
    }
  };

  return (
    <div>
      {!hideVtoButton && (
        <Button
          extraClass="shade-finder__button"
          theme="primary"
          onClick={() => setIsOpen(true)}
        >
          {R.path(['button', 'text'], vto)}
        </Button>
      )}

      <Modal
        className="shade-finder__modal"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick
        style={modalStyles}
      >
        <IconButton
          iconSrc={
            shadeFinderStep === shadeFinderSteps.QUIZ
              ? '/ccx-files/assets/cross-white.svg'
              : '/ccx-files/assets/cross-black.svg'
          }
          onClick={closeModal}
          extraClass="shade-finder__modal-close"
        />

        <div className="shade-finder" id="shade-finder">
          {shadeFinderStep === shadeFinderSteps.POLICY && (
            <PrivacyPolicy
              shadeFinderConfiguration={shadeFinderConfiguration}
              setShadeFinderStep={setShadeFinderStep}
              setPrivacyPolicyConsent={setPrivacyPolicyConsent}
              isShadeFinder={isShadeFinder}
              onReject={() => {
                setPrivacyPolicyConsent(privacyPolicyConsentStates.DISAGREE);
                setShadeFinderStep(shadeFinderSteps.POLICY);
                setIsOpen(false);

                if (onClose) {
                  onClose();
                }
              }}
              vtoType={vtoType}
            />
          )}

          {shadeFinderStep === shadeFinderSteps.QUIZ && (
            <ShadeFinderQuiz
              shadeFinderQuizConfiguration={R.prop(
                'quiz',
                shadeFinderConfiguration
              )}
              setShadeFinderStep={setShadeFinderStep}
              quizAnswers={quizAnswers}
              setQuizAnswers={setQuizAnswers}
              quizResultsMapping={R.path(
                ['quiz', 'results'],
                shadeFinderConfiguration
              )}
              setProductRecommendation={setProductRecommendation}
              vtoType={vtoType}
            />
          )}

          {shadeFinderStep === shadeFinderSteps.GUIDE && (
            <BestResultsGuide
              shadeFinderConfiguration={shadeFinderConfiguration}
              setShadeFinderStep={setShadeFinderStep}
              vtoType={vtoType}
            />
          )}

          {shadeFinderStep === shadeFinderSteps.VTO && (
            <VtoModal
              openVto
              onVtoModalClose={closeModal}
              showStartHero={false}
              privacyPolicyConsent={privacyPolicyConsent}
              vtoActualProductSlug={vtoActualProductSlug}
              productRecommendation={productRecommendation}
              vtoProducts={vtoProducts}
              vtoType={vtoType}
              actualProductSlug={actualProductSlug}
              isShadeFinder={isShadeFinder}
              actualProductSku={actualProductSku}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

ShadeFinder.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  shadeFinderConfiguration: PropTypes.shape({
    triggerButtonLabel: PropTypes.string.isRequired,
    privacyPolicyContent: PropTypes.string.isRequired,
    privacyPolicyLink: PropTypes.string.isRequired,
    privacyPolicyLinkLabel: PropTypes.string.isRequired,
    acceptPolicyButtonLabel: PropTypes.string.isRequired,
    rejectPolicyButtonLabel: PropTypes.string.isRequired,
    instructions: PropTypes.shape({
      instruction: PropTypes.array,
    }).isRequired,
    privacyPolicyConsent: PropTypes.bool,
  }),
  vtoActualProductSlug: PropTypes.string,
  actualProductSku: PropTypes.string,
  vto: PropTypes.object,
  hideVtoButton: PropTypes.bool,
};

export default connect(({ content }) => ({
  vto: R.prop('vto', content),
}))(ShadeFinder);
