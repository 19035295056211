import Picture from '@cotyorg/ccx-atoms/Picture';
import React, { useEffect } from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import Icon from '@cotyorg/ccx-atoms/Icon';
import {
  pageViewTracking,
  productInfoClickTracking,
} from '../../../ShadeFinder/tracking';
import { pagesNames } from '../../../ShadeFinder/helpers/constants';

export const ProductInfoModal = ({
  image,
  heading,
  description,
  onClose,
  vtoType,
}) => {
  useEffect(() => {
    pageViewTracking({ pageName: pagesNames.PERFECT_FOUNDATION, vtoType });
    productInfoClickTracking({ lookName: heading, vtoType });
  }, []);

  return (
    <div className="product-info-modal product-info-modal__overlay">
      <div className="product-info-modal__container">
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
          onKeyDown={(e) => {
            if (onClose && e.key === 'Enter') {
              onClose();
            }
          }}
        >
          <Icon iconSrc="/ccx-files/assets/cross-black.svg" />
        </div>

        <div className="product-info-modal__image-wrapper">
          <Picture
            src={R.prop('imageSrc', image)}
            srcset={R.prop('imageSrcSet', image)}
            alt={R.prop('alt', image)}
            extraClass="product-info-modal__image"
          />
        </div>

        <div className="product-info-modal__content-wrapper">
          <p>{heading}</p>

          <div className="product-info-modal__product-description">
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

ProductInfoModal.propTypes = {
  image: PropTypes.object,
  heading: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func,
  vtoType: PropTypes.func,
};
