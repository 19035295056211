import React, { useState } from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import Icon from '@cotyorg/ccx-atoms/Icon';
import { MiniProductCardoList } from '../../molecules/MiniProductCardoList/MiniProductCardoList';
import { ProductInfoModal } from '../../molecules/ProductInfoModal/ProductInfoModal';

export const ProductSelect = ({
  productSelect,
  selectedProduct,
  miniProductCardoList,
  onOpen,
  onClose,
  onSelect,
  wrapperExtraClass,
  children,
  displayModalButton = true,
  openIconSrc,
  closeIconSrc,
  vtoType,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="vto-product-select">
      <div className={`vto-product-select__wrapper ${wrapperExtraClass}`}>
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            setIsOpen(!isOpen);

            if (!isOpen && onOpen) {
              onOpen();
            }

            if (isOpen && onClose) {
              onClose();
            }
          }}
          onKeyDown={(e) => {
            if (onSelect && e.key === 'Enter') {
              setIsOpen(!isOpen);

              if (!isOpen && onOpen) {
                onOpen();
              }

              if (isOpen && onClose) {
                onClose();
              }
            }
          }}
        >
          <p className="vto-product-select__title">
            {isOpen ? (
              <>
                {R.prop('allProductsButtonText', productSelect)}

                <Icon
                  extraClass="vto-product-select__icon"
                  iconSrc={
                    closeIconSrc ||
                    '/ccx-files/assets/chevron-filled-up-black.svg'
                  }
                />
              </>
            ) : (
              <>
                {R.prop('title', selectedProduct)}

                <Icon
                  extraClass="vto-product-select__icon"
                  iconSrc={
                    openIconSrc ||
                    '/ccx-files/assets/chevron-filled-down-black.svg'
                  }
                />
              </>
            )}
          </p>
        </div>

        {displayModalButton && !isOpen && (
          <button
            type="button"
            onClick={() => setIsModalOpen(true)}
            className="vto-product-select__button"
          >
            {R.prop('productInfoButtonText', productSelect)}
          </button>
        )}
      </div>

      {children}

      {isOpen && (
        <MiniProductCardoList
          miniProductCardoList={miniProductCardoList}
          selectedProduct={selectedProduct}
          onSelect={(miniProductCardo) => {
            setIsOpen(false);

            if (onSelect) {
              onSelect(miniProductCardo);
            }
          }}
        />
      )}

      {isModalOpen && (
        <ProductInfoModal
          image={R.prop('image', selectedProduct)}
          heading={R.prop('title', selectedProduct)}
          description={R.prop('description', selectedProduct)}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          vtoType={vtoType}
        />
      )}
    </div>
  );
};

ProductSelect.propTypes = {
  productSelect: PropTypes.object,
  selectedProduct: PropTypes.object,
  miniProductCardoList: PropTypes.arrayOf(PropTypes.object),
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  wrapperExtraClass: PropTypes.string,
  displayModalButton: PropTypes.bool,
  openIconSrc: PropTypes.string,
  closeIconSrc: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  vtoType: PropTypes.string,
};
