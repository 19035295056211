import { useEffect } from 'react';
import R from 'ramda';
import dispatch from '@cotyorg/ccx-utils/js/tracking/adobe-dtm/events/dispatch';

const onMessageEvent = (event) => {
  const data = R.prop('data', event);
  const channelAdvisorObject = typeof data === 'string' ? JSON.parse(data) : {};

  if (R.prop('type', channelAdvisorObject) === 'channelAdvisorBuyNowEvent') {
    const detail = {
      eventInfo: R.prop('buyNow', channelAdvisorObject),
    };

    dispatch({
      eventName: 'buyNow',
      detail,
    });
  }
};

export const useAdobe = () =>
  useEffect(() => {
    if (window) {
      window.addEventListener('message', onMessageEvent);
    }

    return () => window.removeEventListener('message', onMessageEvent);
  }, []);
