import React, { useState } from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import Picture from '@cotyorg/ccx-atoms/Picture';
import Link from '@cotyorg/ccx-atoms/Link';
import Button from '@cotyorg/ccx-atoms/Button';
import Icon from '@cotyorg/ccx-atoms/Icon';
import ChannelAdvisorWidget from '../../../../../molecules/ChannelAdvisorWidget';

export const ProductCard = ({
  productCard,
  buyNowButtonText,
  channelAdvisorSourceUrl,
  channelAdvisorPid,
  onBuyButtonClick,
}) => {
  const [showChannelAdvisor, setShowChannelAdvisor] = useState(false);

  const handleChannelAdvisor = () =>
    setShowChannelAdvisor((prevValue) => !prevValue);

  const productUrl = `${R.prop('productUrl', productCard)}${`?shade=${R.prop(
    'itemName',
    productCard
  )}`}`;

  return (
    <div className="shade-finder__shopping-cart-product-card">
      <div className="grid-x">
        <div className="xsmall-5">
          <div className="shade-finder__shopping-cart-product-card-image">
            <Picture
              alt={R.prop('itemName', productCard)}
              src={R.path(['image', 'imageSrc'], productCard)}
              srcset={R.path(['image', 'imageSrcSet'], productCard)}
            />
          </div>
        </div>

        <div className="xsmall-11 shade-finder__shopping-cart-product-card-content">
          <div className="shade-finder__shopping-cart-product-card-title">
            {R.prop('title', productCard)}
          </div>

          <div className="shade-finder__shopping-cart-product-card-shade">
            <div
              className="shade-finder__shopping-cart-product-card-shade-name-color"
              style={{ backgroundColor: R.prop('skuColor', productCard) }}
            />

            <div className="shade-finder__shopping-cart-product-card-shade-name">
              {R.prop('itemName', productCard)}
            </div>
          </div>

          <div className="shade-finder__shopping-cart-product-card-buy-button">
            {channelAdvisorSourceUrl && (
              <Button
                extraClass="button--link"
                onClick={() => {
                  handleChannelAdvisor();
                  if (onBuyButtonClick) {
                    onBuyButtonClick({ productCard, productUrl });
                  }
                }}
              >
                {buyNowButtonText}
              </Button>
            )}
            {!channelAdvisorSourceUrl && (
              <Link
                link={productUrl}
                target="_blank"
                onClick={() => {
                  if (onBuyButtonClick) {
                    onBuyButtonClick({ productCard, productUrl });
                  }
                }}
              >
                {buyNowButtonText}
              </Link>
            )}
          </div>
        </div>

        {showChannelAdvisor && (
          <div className="channel-advisor-widget-wrapper">
            <span
              aria-hidden="true"
              onClick={() => handleChannelAdvisor()}
              className="channel-advisor-widget-wrapper__close"
            >
              <Icon iconSrc="/ccx-files/assets/cross-black.svg" />
            </span>

            <ChannelAdvisorWidget
              selectedSKU={R.prop('sku', productCard)}
              url={channelAdvisorSourceUrl}
              caPid={channelAdvisorPid}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  buyNowButtonText: PropTypes.string,
  channelAdvisorSourceUrl: PropTypes.string,
  channelAdvisorPid: PropTypes.string,
  productCard: PropTypes.object,
  onBuyButtonClick: PropTypes.func,
};
