import React from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import { MiniProductCardoListItem } from './MiniProductCardoListItem';

export const MiniProductCardoList = ({
  miniProductCardoList,
  selectedProduct,
  onSelect,
}) => (
  <div className="mini-product-cardo-list">
    <div className="mini-product-cardo-list__wrapper">
      {R.map(
        (miniProductCardo) => (
          <MiniProductCardoListItem
            key={R.prop('slug', miniProductCardo)}
            miniProductCardo={miniProductCardo}
            onSelect={onSelect}
            selectedProduct={selectedProduct}
          />
        ),
        miniProductCardoList
      )}
    </div>
  </div>
);

MiniProductCardoList.propTypes = {
  miniProductCardoList: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedProduct: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
};
