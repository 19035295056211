import React from 'react';
import PropTypes from 'prop-types';
import Button from '@cotyorg/ccx-atoms/Button';
import R from 'ramda';
import Answer from './Answer';

const Question = ({
  data,
  quizStep,
  setQuizStep,
  quizLength,
  backButtonLabel,
  quizAnswers,
  setQuizAnswers,
  vtoType,
}) => {
  const { prompt, answers } = data;

  return (
    <div className="shade-finder-quiz__question">
      <h2 className="shade-finder-quiz__question-prompt">{prompt}</h2>

      <div className="shade-finder-quiz__question-answers-list">
        {answers.length &&
          R.map(
            (answer, index) => (
              <Answer
                key={index}
                answer={answer}
                quizStep={quizStep}
                setQuizStep={setQuizStep}
                quizLength={quizLength}
                quizAnswers={quizAnswers}
                setQuizAnswers={setQuizAnswers}
                vtoType={vtoType}
              />
            ),
            answers
          )}
      </div>

      {quizStep > 0 && (
        <Button
          theme="primary"
          extraClass="shade-finder-quiz__back-button"
          onClick={() => {
            setQuizStep(quizStep - 1);
          }}
        >
          {backButtonLabel}
        </Button>
      )}
    </div>
  );
};

Question.propTypes = {
  data: PropTypes.shape({
    prompt: PropTypes.string.isRequired,
    answers: PropTypes.array.isRequired,
  }).isRequired,
  quizStep: PropTypes.number.isRequired,
  setQuizStep: PropTypes.func.isRequired,
  quizLength: PropTypes.number.isRequired,
  backButtonLabel: PropTypes.string.isRequired,
  quizAnswers: PropTypes.array.isRequired,
  setQuizAnswers: PropTypes.func.isRequired,
  vtoType: PropTypes.string,
};

export default Question;
