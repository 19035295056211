export const shadeFinderSteps = {
  POLICY: 'policy',
  GUIDE: 'guide',
  QUIZ: 'quiz',
  VTO: 'vto',
};
export const pagesNames = {
  TRY_ON: 'try on',
  FOR_BEST_RESULT: 'for best results',
  PRIVACY_POLICY: 'privacy policy',
  CAMERA_ACCESS: 'camera access',
  PERFECT_FOUNDATION: 'perfect foundation',
  PREFERRED_FINISH: 'preferred finish',
  FOUNDATION_COVERAGE: 'foundation coverage',
  ADDITIONAL_BENEFIT: 'additional benefit',
  CURRENTLY_TRYING_ON: 'currently trying on',
};
export const VTO_VERSION = '1.0';

export const privacyPolicyConsentStates = {
  AGREE: 'agree',
  DISAGREE: 'disagree',
};
