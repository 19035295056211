export const eventsNames = {
  VTO_EVENT_CLOSED: 'vto-event-closed',
  VTO_PRODUCT_EVENT_EXPERIENCE_LOADED: 'vto-product-event-experience-loaded',
  VTO_GENERIC_EVENT_CAMERA_OPEN: 'vto-generic-event-camera-open',
  VTO_GENERIC_EVENT_CAMERA_CLOSED: 'vto-generic-event-camera-closed',
  VTO_GENERIC_EVENT_REMOVE_COLOUR: 'vto-generic-event-remove-colour',
  VTO_GENERIC_EVENT_PHOTO_SAVED: 'vto-generic-event-photo-saved',
  VTO_ERROR_CAMERA_FAILED: 'vto-error-camera-failed',
  VTO_GENERIC_EVENT_CONSENT_SHOWED: 'vto-generic-event-consent-showed',
  VTO_GENERIC_EVENT_CONSENT_AGREED: 'vto-generic-event-consent-agreed',
  VTO_GENERIC_EVENT_ICON_KEY_VIEW_CLOSED:
    'vto-generic-event-icon-key-view-closed',
  VTO_PRODUCT_EVENT_SLIDER_COMPARE: 'vto-product-event-slider-compare',
  VTO_PRODUCT_EVENT_SLIDER_COMPARE_CLOSED:
    'vto-product-event-slider-compare-closed',
  VTO_GENERIC_EVENT_PHOTO_TAKEN: 'vto-generic-event-photo-taken',
  VTO_ERROR_NO_HAND_DETECTED: 'vto-error-no-hand-detected',
  VTO_GENERIC_EVENT_ICON_KEY_BTN_CLICKED:
    'vto-generic-event-icon-key-btn-clicked',
  SPLIT_FOUR_WAY: 'split-four-way',
  SPLIT_FOUR_WAY_CLOSED: 'split-four-way-closed',
  VTO_PRODUCT_EVENT_SHADE_SELECT: 'vto-product-event-shade-select',
  VTO_PAGE_IMPRESSION: 'vto-page-impression',
  VTO_GENERIC_EVENT_GET_STARTED: 'vto-generic-event-get-started',
  VTO_GENERIC_EVENT_SELECT_LINE: 'vto-generic-event-select-line',
  VTO_GENERIC_EVENT_FILTER_APPLY: 'vto-generic-event-filter-apply',
  VTO_GENERIC_EVENT_FILTER_RESET: 'vto-generic-event-filter-reset',
  VTO_PRODUCT_EVENT_SHOP_NOW: 'vto-product-event-shop-now',
  VTO_GENERIC_EVENT_FILTER_SELECT: 'vto-generic-event-filter-select',
  VTO_GENERIC_EVENT_SELECT_FILTER_OPEN: 'vto-generic-event-select-filter-open',
  PRODUCT_TRY_ON: 'product-try-on',
  VTO_LOADING: 'loading',
  VTO_PRODUCT_EVENT_RESET: 'reset',
  VTO_PRODUCT_EVENT_SPLIT: 'compare',
};

export const eventsTypes = {
  VTO_PRODUCT_TRY_ON: 'product-try-on',
  VTO_PAGE_IMPRESSION: 'vto-page-impression',
  VTO_PRODUCT_EVENT: 'vto-product-event',
  VTO_GENERIC_EVENT: 'vto-generic-event',
  VTO_ERROR: 'vto-error',
};
