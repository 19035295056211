export const isLargeBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('large');

export const isMediumBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('medium');

export const isSmallBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('small');

export const isXSmallBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('xsmall');
