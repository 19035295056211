import React from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import { connect } from 'react-redux';
import { Cookie } from '@cotyorg/ccx-organism-cookie';
import { HeaderTwoRow } from '@cotyorg/ccx-organism-header';
import { FooterTwoRow } from '@cotyorg/ccx-organism-footer';
import { Social } from '@cotyorg/ccx-molecules/Social';
import Search from '@cotyorg/ccx-organism-search/components/Search';
import Heading from '@cotyorg/ccx-atoms/Heading';
import OptionalSection from '@cotyorg/ccx-utils/js/components/OptionalSection';
import BBCodeParser from '@cotyorg/ccx-utils/js/components/BBCodeParser';

const StandardLayout = ({
  contactDetails,
  cookieContent,
  children,
  isServedFromLegacySite,
  socialHeading,
  search,
  presentationLocale,
  isOneTrustEnabled,
}) => (
  <>
    <HeaderTwoRow
      logoSrc="/ccx-files/assets/logo.svg"
      logoAlt="Manhattan Cosmetics"
      leftChevronIconSrc="/ccx-files/assets/arrow-left.svg"
      rightChevronIconSrc="/ccx-files/assets/arrow-right.svg"
      closeIconSrc="/ccx-files/assets/cross-white.svg"
      hamburgerIconSrc="/ccx-files/assets/hamburger.svg"
      logoPosition="center"
      themeName="filled"
      navType="featuredProducts"
      showSearch
      searchIconSrc="/ccx-files/assets/search.svg"
      logoWidth={305}
      logoHeight={75}
    />
    <Search
      appElement="#app"
      searchIconSrc="/ccx-files/assets/search-simple.svg"
      closeIconSrc="/ccx-files/assets/cancel-simple.svg"
      clearSearchIconSrc="/ccx-files/assets/erase-simple.svg"
      searchURL={R.prop('url')(search)}
      locale={R.prop('locale')(search)}
      presentationLocale={presentationLocale}
      searchPath="/search/"
      searchFieldName="search"
      searchButtonHidden
      isServedFromLegacySite={isServedFromLegacySite}
    />

    <div className="page__main-content">{children}</div>

    <div className="social__background">
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell medium-8">
            <div className="social__wrapper">
              <Social
                socialAlignment="left"
                socialIconSrc={{
                  facebook: '/ccx-files/assets/facebook-white.svg',
                  instagram: '/ccx-files/assets/instagram-white.svg',
                  pinterest: '/ccx-files/assets/pinterest-white.svg',
                  tiktok: '/ccx-files/assets/tiktok-white.svg',
                  twitter: '/ccx-files/assets/twitter-white.svg',
                  youtube: '/ccx-files/assets/youtube-white.svg',
                }}
                heading={socialHeading}
              />
            </div>
          </div>
          <div className="cell medium-8">
            <Heading level={5} extraClass="social-contact-heading">
              <BBCodeParser>
                {R.prop('description', contactDetails)}
              </BBCodeParser>
            </Heading>
          </div>
        </div>
      </div>
    </div>

    <FooterTwoRow
      isServedFromLegacySite={isServedFromLegacySite}
      minusIconSrc="/ccx-files/assets/arrow-left.svg"
      plusIconSrc="/ccx-files/assets/arrow-left.svg"
      countrySelector={{
        visible: true,
        beforeCopyright: true,
        showToggleIcon: false,
      }}
      navigationLinkIconWidth={8}
      navigationLinkIconHeight={14}
    />
    <OptionalSection
      renderCondition={!isOneTrustEnabled}
      section={() => (
        <Cookie
          position="bottom"
          content={cookieContent}
          closeIconSrc="/ccx-files/assets/cross-white.svg"
        />
      )}
    />
  </>
);

StandardLayout.propTypes = {
  contactDetails: PropTypes.shape({
    description: PropTypes.string,
  }),
  cookieContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    dismissText: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  isServedFromLegacySite: PropTypes.bool,
  socialHeading: PropTypes.string,
  search: PropTypes.shape({
    url: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    contentLabel: PropTypes.string,
    placeholderText: PropTypes.string,
    clearSearchTermHistoryButtonText: PropTypes.string,
    popularSearches: PropTypes.array,
  }),
  presentationLocale: PropTypes.string.isRequired,
  isOneTrustEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  cookieContent: R.path(['content', 'cookie'], state),
  contactDetails: R.path(['content', 'contactDetails'], state),
  isServedFromLegacySite: R.path(['status', 'isServedFromLegacySite'], state),
  search: R.path(['content', 'search'], state),
  presentationLocale: R.prop('presentationLocale', state),
  isOneTrustEnabled: R.path(['content', 'isOneTrustEnabled'], state),
});

export default connect(mapStateToProps)(StandardLayout);
