import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import { Swatches } from '../../molecules/Swatches/Swatches';

export const BestMatch = ({
  selectedProduct,
  vtoActualProductSku,
  onSetProductID,
  setVtoActualProductSku,
  swatches,
  setVtoActualProductSlug,
  vtoType,
}) => {
  const swatchesRef = useRef();

  useEffect(() => {
    setVtoActualProductSlug({
      actualProductSlug: R.prop('slug', selectedProduct),
    });
  }, []);

  useEffect(() => {
    if (swatchesRef.current) {
      const matchedSwatchIndex = R.findIndex(
        R.propEq('id', 'matched'),
        swatches || []
      );
      const bestMatchSwatchRef =
        swatchesRef.current.children[matchedSwatchIndex];

      if (bestMatchSwatchRef) {
        bestMatchSwatchRef.classList.add('best-match-swatch');
      }
    }
  }, [swatchesRef.current, swatches]);

  return (
    <div className="best-match">
      <Swatches
        swatches={swatches}
        vtoActualProductSku={vtoActualProductSku}
        onChange={({ sku }) => {
          if (!sku && window.YMK && window.YMK.reset) {
            try {
              YMK.reset();
            } catch (error) {
              console.error(error);
            }
          }

          onSetProductID({
            sku,
            swatches,
            setVtoActualProductSku,
            vtoType,
          });
        }}
        setVtoActualProductSku={setVtoActualProductSku}
        ref={swatchesRef}
        vtoType={vtoType}
      />
    </div>
  );
};

BestMatch.propTypes = {
  selectedProduct: PropTypes.object,
  vtoActualProductSku: PropTypes.string,
  onSetProductID: PropTypes.func,
  setVtoActualProductSku: PropTypes.string,
  swatches: PropTypes.arrayOf(PropTypes.object),
  setVtoActualProductSlug: PropTypes.string,
  vtoType: PropTypes.string.isRequired,
};
