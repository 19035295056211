import React, { useEffect, useState } from 'react';
import Button from '@cotyorg/ccx-atoms/Button';
import Checkbox from '@cotyorg/ccx-atoms/Checkbox';
import Icon from '@cotyorg/ccx-atoms/Icon';
import PropTypes from 'prop-types';
import {
  pagesNames,
  shadeFinderSteps,
  privacyPolicyConsentStates,
} from '../helpers/constants';
import { pageViewTracking, privacyPolicyAgreeTracking } from '../tracking';
import { vtoTypes } from '../../VtoModal/config';

const PrivacyPolicy = ({
  shadeFinderConfiguration,
  setShadeFinderStep,
  onReject,
  setPrivacyPolicyConsent,
  isShadeFinder,
  vtoType,
}) => {
  const [policyAccepted, setPolicyAccepted] = useState(false);

  useEffect(
    () => pageViewTracking({ pageName: pagesNames.PRIVACY_POLICY, vtoType }),
    []
  );

  const acceptPrivacyPolicy = () => {
    const steps = {
      [vtoTypes.MAKEUP_FINDER]: shadeFinderSteps.GUIDE,
      [vtoTypes.SHADE_FINDER]: shadeFinderSteps.QUIZ,
      [vtoTypes.NAIL_VTO]: shadeFinderSteps.VTO,
    };

    setPrivacyPolicyConsent(privacyPolicyConsentStates.AGREE);
    setShadeFinderStep(steps[vtoType]);
    privacyPolicyAgreeTracking({ vtoType });
  };

  const {
    privacyPolicyContent,
    privacyPolicyLink,
    privacyPolicyLinkLabel,
    acceptPolicyButtonLabel,
    rejectPolicyButtonLabel,
  } = shadeFinderConfiguration;

  return (
    <div className="shade-finder__privacy-policy">
      <Icon
        iconSrc="/ccx-files/assets/logo-black.svg"
        iconExtraClass="shade-finder__privacy-policy-icon"
      />
      <p className="shade-finder__privacy-policy-content">
        {privacyPolicyContent}
        <a
          href={privacyPolicyLink}
          target="_blank"
          rel="noreferrer"
          className="shade-finder__privacy-policy-content-link"
        >
          {privacyPolicyLinkLabel}
        </a>
      </p>

      <Checkbox
        onChange={(e) => {
          setPolicyAccepted(e.target.checked);
        }}
        name="checkbox-shade-finder-policy"
        id="checkbox-shade-finder-policy"
        extraClass="shade-finder__privacy-policy-accept-checkbox"
        required
      />

      <Button
        theme="primary"
        disabled={!policyAccepted}
        onClick={acceptPrivacyPolicy}
        extraClass={!policyAccepted ? 'button--disabled' : ''}
      >
        {acceptPolicyButtonLabel}
      </Button>

      <Button extraClass="button--link" onClick={onReject}>
        {rejectPolicyButtonLabel}
      </Button>
    </div>
  );
};

PrivacyPolicy.propTypes = {
  shadeFinderConfiguration: PropTypes.shape({
    triggerButtonLabel: PropTypes.string,
    privacyPolicyContent: PropTypes.string,
    privacyPolicyLink: PropTypes.string,
    privacyPolicyLinkLabel: PropTypes.string,
    acceptPolicyButtonLabel: PropTypes.string,
    rejectPolicyButtonLabel: PropTypes.string,
    instructions: PropTypes.shape({
      instruction: PropTypes.array,
    }),
  }),
  setShadeFinderStep: PropTypes.func.isRequired,
  onReject: PropTypes.func,
  setPrivacyPolicyConsent: PropTypes.func,
  isShadeFinder: PropTypes.bool,
  vtoType: PropTypes.string,
};

export default PrivacyPolicy;
