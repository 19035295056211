import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const generateSwatchesClassNames = ({ extraClass }) =>
  classNames(`shadow__wrapper ${extraClass}`);

export const ShadowWrapper = ({ children, extraClass = '' }) => (
  <div className={generateSwatchesClassNames({ extraClass })}>
    <div className="shadow">{children}</div>
  </div>
);

ShadowWrapper.propTypes = {
  children: React.Node,
  extraClass: PropTypes.string,
};
