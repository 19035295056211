import { useEffect } from 'react';
import R from 'ramda';
import { vtoTypes, VTO_IFRAME_BUTTONS_SHIFT } from '../config';
import { selectVtoLaunchMethod } from '../helpers';

export const useVtoInitialisation = ({
  setVtoScriptLoaded,
  currentBreakpoint,
  setVtoControlsHeight,
  isXSmallBreakpoint,
  isSmallBreakpoint,
  isMediumBreakpoint,
  perfectModuleLanguage,
  disabledEmailSharing,
  enableFBSharing,
  privacyPolicyConsent,
  setVtoActualProductSku,
  actualProductSku,
  vtoType,
  setShowTabs,
  setActiveTab,
  vtoProducts,
  actualProductSlug,
  isVtoOpened,
}) => {
  useEffect(() => {
    if (window) {
      window.ymkAsyncInit = () => {
        const isXSmallBreakpointValue = isXSmallBreakpoint({
          currentBreakpoint,
        });
        const isSmallBreakpointValue = isSmallBreakpoint({
          currentBreakpoint,
        });

        const isMediumBreakpointValue = isMediumBreakpoint({
          currentBreakpoint,
        });

        let iframeSizes;
        const vtoControlsHeight = 160;
        const desktopVersionVtoMargin = 60;

        if (
          (isXSmallBreakpointValue || isSmallBreakpointValue) &&
          !!window.document.body.requestFullscreen
        ) {
          iframeSizes = {
            height: window.innerHeight - vtoControlsHeight,
          };
        } else if (isXSmallBreakpointValue || isSmallBreakpointValue) {
          iframeSizes = {
            height:
              window.document.documentElement.clientHeight - vtoControlsHeight,
          };
        } else if (isMediumBreakpointValue) {
          iframeSizes = {
            height:
              window.document.documentElement.clientHeight - vtoControlsHeight,
            width: window.document.documentElement.clientWidth,
          };
        } else {
          iframeSizes = {
            height:
              window.document.documentElement.clientHeight -
              vtoControlsHeight -
              desktopVersionVtoMargin,
            width: 600,
          };
        }

        YMK.init({
          ...iframeSizes,
          // enableEmailSharing: !disabledEmailSharing,
          // enableFBSharing,
          hideShareButton: true,
          SenenableUserNailReplacement: true,
          language: perfectModuleLanguage || 'enu',
          rightButtonBottomOffset: VTO_IFRAME_BUTTONS_SHIFT,
          userConsent: privacyPolicyConsent,
          enableFourWayLooks: vtoType === vtoTypes.NAIL_VTO,
        });

        const launchSelectedVtoType = selectVtoLaunchMethod({
          vtoType,
        });
        if (launchSelectedVtoType !== YMK.openShadeFinder) {
          launchSelectedVtoType(true);
        } else {
          launchSelectedVtoType();
        }

        if (vtoType === vtoTypes.NAIL_VTO && window.YMK) {
          YMK.enableCompare();
        } else {
          YMK.disableCompare();
        }

        setVtoControlsHeight({ vtoControlsHeight });
        setVtoScriptLoaded({ vtoScriptLoaded: true });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentBreakpoint,
    setVtoScriptLoaded,
    isXSmallBreakpoint,
    isSmallBreakpoint,
    isMediumBreakpoint,
    setVtoControlsHeight,
    vtoType,
  ]);

  useEffect(() => {
    if (
      vtoType !== vtoTypes.SHADE_FINDER &&
      !!actualProductSku &&
      isVtoOpened
    ) {
      const sku = R.replace(/^0*/, '', actualProductSku);
      const selectedProduct = R.find(
        R.propEq('slug', actualProductSlug),
        vtoProducts
      );

      setVtoActualProductSku({ actualProductSku: sku });
      setShowTabs({ showTabs: false });
      setActiveTab({ activeTab: R.prop('category', selectedProduct) });

      if (window.YMK) {
        YMK.applyMakeupBySku(sku);
      }
    }
  }, [isVtoOpened]);
};

export const useSetActualProductData = ({
  actualProductSku,
  setVtoActualProductSlug,
  setVtoActualProductSku,
  vtoProducts,
}) =>
  useEffect(() => {
    const actualProduct = R.find(
      (product) =>
        !!R.find(
          R.propEq('sku', actualProductSku),
          R.propOr([], 'variants', product)
        ),
      vtoProducts
    );

    if (actualProduct) {
      setVtoActualProductSlug({
        actualProductSlug: R.prop('slug', actualProduct),
      });
      setVtoActualProductSku({ actualProductSku });
    }
    // eslint-disable-next-line
  }, [actualProductSku, setVtoActualProductSlug, setVtoActualProductSku]);
