import React from 'react';
import PropTypes from 'prop-types';

const CloseButton = ({ imageSrc, onClick }) => (
  <input
    type="image"
    alt="cross icon"
    className="vto__modal-close-button"
    src={imageSrc}
    onClick={onClick}
  />
);

CloseButton.propTypes = {
  imageSrc: PropTypes.string,
  onClick: PropTypes.func,
};

export default CloseButton;
