import R from 'ramda';

export const findProductRecommendation = ({
  quizAnswers,
  quizResultsMapping,
}) => {
  const productRecommendation = R.find(
    (result) => R.includes(quizAnswers, R.prop('paths', result)),
    quizResultsMapping
  );

  return R.prop('product', productRecommendation);
};
