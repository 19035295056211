import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import { Swatches } from '../../molecules/Swatches/Swatches';
import { ShadowWrapper } from '../../atoms/ShadowWrapper';

export const AllShades = ({
  defaultProductSku,
  vtoActualProductSku,
  onSetProductID,
  setVtoActualProductSku,
  swatches,
  onBackwardButtonClick,
  showBackwardButton = false,
  enableRemoveSelectionSwatch,
  vtoType,
}) => {
  const swatchesRef = useRef();

  useEffect(() => {
    if (swatchesRef.current && !!defaultProductSku) {
      const selectedSwatchListElement = R.find(
        (swatchListElement) =>
          swatchListElement.firstChild.id ===
          `${R.replace(/^0*/, '', defaultProductSku)}vto-modal`,
        swatchesRef.current.children
      );

      if (selectedSwatchListElement) {
        selectedSwatchListElement.scrollIntoView({
          inline: 'center',
        });
      }
    }
  }, []);

  return (
    <div className="all-shades">
      {showBackwardButton && (
        <button
          type="button"
          onClick={onBackwardButtonClick}
          className="all-shades__backward-button"
        >
          <img
            className="all-shades__backward-button-image"
            src="/ccx-files/assets/chevron-left.svg"
            alt=""
          />
        </button>
      )}

      <ShadowWrapper>
        <Swatches
          ref={swatchesRef}
          swatches={swatches}
          vtoActualProductSku={vtoActualProductSku}
          onSetProductID={onSetProductID}
          setVtoActualProductSku={setVtoActualProductSku}
          enableRemoveSelectionSwatch={enableRemoveSelectionSwatch}
          vtoType={vtoType}
          onChange={({ sku }) => {
            if (!sku && window.YMK && window.YMK.reset) {
              try {
                YMK.reset();
              } catch (error) {
                console.error(error);
              }
            }

            onSetProductID({
              sku,
              swatches,
              setVtoActualProductSku,
              vtoType,
            });
          }}
        />
      </ShadowWrapper>
    </div>
  );
};

AllShades.propTypes = {
  vtoActualProductSku: PropTypes.string,
  onSetProductID: PropTypes.func,
  setVtoActualProductSku: PropTypes.string,
  swatches: PropTypes.arrayOf(PropTypes.object),
  enableRemoveSelectionSwatch: PropTypes.bool,
  onBackwardButtonClick: PropTypes.func,
  showBackwardButton: PropTypes.bool,
  vtoType: PropTypes.string.isRequired,
  defaultProductSku: PropTypes.string,
};
