import React, { useEffect } from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import IconButton from '@cotyorg/ccx-molecules/IconButton';
import { ProductCard } from './ProductCard';
import { buyNowListViewTracking, pageViewTracking } from '../../../tracking';
import { pagesNames } from '../../../helpers/constants';

export const ShoppingCart = ({
  cart,
  recommendedProducts = [],
  heading,
  recommendedProductsHeading,
  onCloseClick,
  buyNowButtonText,
  onBuyButtonClick,
  vtoType,
  channelAdvisorSourceUrl,
  channelAdvisorPid,
}) => {
  useEffect(() => {
    pageViewTracking({ pageName: pagesNames.CURRENTLY_TRYING_ON, vtoType });
    console.log('cart: ', cart);
    buyNowListViewTracking({ products: R.uniq(R.values(cart)), vtoType });
  }, []);

  return (
    <div className="shade-finder__shopping-cart">
      <div>
        <div className="shade-finder__shopping-cart-heading">
          {heading}
          <IconButton
            extraClass="shade-finder__shopping-cart-close-button"
            iconSrc="/ccx-files/assets/cross-black.svg"
            onClick={onCloseClick}
          />
        </div>

        <div className="shade-finder__shopping-cart-product-list">
          {R.map(
            (productCard) =>
              R.prop('sku', productCard) && (
                <ProductCard
                  productCard={productCard}
                  key={R.prop('sku', productCard)}
                  buyNowButtonText={buyNowButtonText}
                  channelAdvisorSourceUrl={channelAdvisorSourceUrl}
                  channelAdvisorPid={channelAdvisorPid}
                  onBuyButtonClick={onBuyButtonClick}
                />
              ),
            R.uniq(R.values(cart))
          )}
        </div>
      </div>

      {recommendedProducts.length > 0 && (
        <div className="shade-finder__shopping-cart-recommended-products">
          <div className="shade-finder__shopping-cart-recommended-products-heading">
            {recommendedProductsHeading}
          </div>

          <div className="shade-finder__shopping-cart-recommended-products-list">
            {R.map(
              (productCard) =>
                R.prop('sku', productCard) && (
                  <ProductCard
                    productCard={productCard}
                    key={R.prop('sku', productCard)}
                    buyNowButtonText={buyNowButtonText}
                    channelAdvisorSourceUrl={channelAdvisorSourceUrl}
                    channelAdvisorPid={channelAdvisorPid}
                  />
                ),
              recommendedProducts
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ShoppingCart.propTypes = {
  cart: PropTypes.object,
  recommendedProducts: PropTypes.array,
  heading: PropTypes.string,
  recommendedProductsHeading: PropTypes.array,
  onCloseClick: PropTypes.func,
  buyNowButtonText: PropTypes.string,
  onBuyButtonClick: PropTypes.func,
  vtoType: PropTypes.string,
  channelAdvisorSourceUrl: PropTypes.string,
  channelAdvisorPid: PropTypes.string,
};
